import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import {
  CustomCarousel,
  CustomDialog,
  icons,
  dateUtils
} from '@alfred-co/alfred-react-components';

import useTrackingEventsStyles from './trackingEventsStyles';
import { setTrackingMap } from '../../../redux/actions/tracking/trackingActions';
import { IMAGE_FORMATS, VIDEO_FORMATS } from '../../../utils/formats';

const { Route, Image, RigthArrow } = icons;
const { toShortHour } = dateUtils;

const TrackingEvents = ({ events }) => {
  const classes = useTrackingEventsStyles();
  const [ openModal, setOpenModal ] = useState( '' );
  const [ eventSelected, setEventSelected ] = useState({});
  const dispatch = useDispatch();

  useEffect( () => {
    if( !events ) return;
    const map = events.reduce( ( result, event ) => {
      const { data } = event;
      if( data?.map ) {
        result.push( data?.map );
      }
      return result;
    }, [] );

    if( map?.length ) {
      dispatch( setTrackingMap( map[ map.length - 1 ], false ) );
    }
  }, [ dispatch, events ] );


  const handleOpenModal = useCallback(
    ( uuid ) => {
      setOpenModal( uuid );
      setEventSelected( events.find( ( event ) => event.uuid === uuid ) );
    },
    [ events ]
  );

  const closeModal = useCallback( () => {
    setOpenModal( '' );
  }, [ ] );

  const handleSetTracking = useCallback(
    ( map ) => {
      dispatch( setTrackingMap( map, true ) );
    },
    [ dispatch ]
  );

  return (
    <>
      {
        events.map( ( event ) => (
          <Grid key={ event.uuid } container alignItems="center" className={ classes.trackingEventEventContainer }>
            <Grid item container wrap="nowrap">
              <img src={ RigthArrow } alt="Imagen" />
              <Typography variant="body2" className={ classes.trackingEventEventName }>
                { event?.eventType?.name }
                { ' ' }
                -
                { ' ' }
                { toShortHour( new Date( event?.executionDate ) ) }
              </Typography>
            </Grid>
            {
              event?.data?.images.length > 0 && (
                <Grid container alignItems="center" onClick={ () => handleOpenModal( event.uuid ) } className={ classes.trackingEventIconContainer }>
                  <img src={ Image } alt="Imagen" />
                  <Typography variant="overline" className={ classes.trackingEventImageText }>Ver galería</Typography>
                </Grid>
              )
            }
            {
              event?.data?.map && (
                <Grid container alignItems="center" onClick={ () => handleSetTracking( event?.data?.map ) } className={ classes.trackingEventIconContainer }>
                  <img src={ Route } alt="service tracking" />
                  <Typography variant="overline" className={ classes.trackingEventImageText }>Ver tracking</Typography>
                </Grid>
              )
            }
          </Grid>
        ) )
      }
      <CustomDialog
        open={ !!openModal }
        handler={ closeModal }
        title={ eventSelected?.eventType?.name }
        modalWidth={ 725 }
        customDialogContent={ classes.trackingCustomDialog }
      >
        { eventSelected?.data?.images.length > 0 && (
          <Grid container className={ classes.trackingEventSliderContainer }>
            <CustomCarousel
              components={ eventSelected?.data?.images?.map( ( file ) => {
                const isImage = IMAGE_FORMATS.some( ( ext ) => file?.includes( ext ) );
                const isVideo = VIDEO_FORMATS.some( ( ext ) => file?.includes( ext ) );

                if( isImage ) {
                  return (
                    <Grid key={ file } container alignItems="center" justifyContent="center" className={ classes.trackingEventImageContainer }>
                      <img
                        className={ classes.trackingEventImage }
                        src={ file }
                        alt="event"
                      />
                    </Grid>
                  );
                }
                if( isVideo ) {
                  return (
                    <Grid key={ file } container alignItems="center" justifyContent="center" className={ classes.trackingEventImageContainer }>
                      <video
                        className={ classes.trackingEventVideo }
                        src={ file }
                        controls
                      >
                        <track kind="captions" src="" label="English" />
                      </video>
                    </Grid>
                  );
                }

                return (
                  <Grid key={ file } container alignItems="center" justifyContent="center" className={ classes.trackingEventImageContainer }>
                    <img
                      className={ classes.trackingEventImage }
                      src={ file }
                      alt="event"
                    />
                  </Grid>
                );
              }) }
              showPagination
            />
          </Grid>
        ) }
      </CustomDialog>
    </>
  );
};

export default TrackingEvents;
